//
// User Login v3
// Pages SASS files are compiled into separate css files
//



// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

// Login Base
.kt-login.kt-login--v4 {
    background-size: cover;
    background-repeat: no-repeat;

    // Login Area
    .kt-login__wrapper {
        padding: 6% 2rem 1rem 2rem;
        margin: 0 auto 2rem auto;
        overflow: hidden;

        .kt-login__container {
            width: 430px;
            margin: 0 auto;

            .kt-login__logo {
                text-align: center;
                margin: 0 auto 4rem auto;
            }

            .kt-login__head {
                margin-top: 1rem;
                margin-bottom: 3rem;

                .kt-login__title {
                    text-align: center;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: kt-base-color(label, 3);
                }

                .kt-login__desc {
                    margin-top: 1.5rem;
                    text-align: center;
                    font-size: 1.1rem;
                    font-weight: 400;
                    color: kt-base-color(label, 2);
                }
            }

            .kt-form {
                margin: 0 auto;

                .input-group {
                    padding: 0;
                    margin: 0 auto;
                }

                .form-control {
                    height: 46px;
                    border: none;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    border-radius: 46px;
                    margin-top: 1.5rem;
                    background: rgba(#fff, 0.015);
                    color: kt-base-color(label, 2);
                    @include kt-input-placeholder(kt-base-color(label, 3));
                }

                .form-control.is-valid + .valid-feedback,
                .form-control.is-invalid + .invalid-feedback {
                    font-weight: 500;
                    font-size: 0.9rem;
                    padding-left: 1.6rem;
                }

                .kt-login__extra {
                    margin-top: 30px;
                    margin-bottom: 15px;
                    color: kt-base-color(label, 2);
                    font-size: 1rem;
                    padding: 0 1.5rem;

                    .kt-checkbox {
                        font-size: 1rem;
                    }

                    .kt-login__link {
                        font-size: 1rem;
                        color: kt-base-color(label, 2);
                        transition: color 0.3s ease;

                        &:hover {
                            color: kt-brand-color();
                            transition: color 0.3s ease;
                        }
                    }
                }

                .kt-login__actions {
                    text-align: center;
                    margin-top: 7%;

                    .kt-login__btn-secondary,
                    .kt-login__btn-primary {
                        height: 50px;
                        padding-left: 2.5rem;
                        padding-right: 2.5rem;
                    }
                }
            }

            .kt-login__account {
                text-align: center;
                margin-top: 2rem;

                .kt-login__account-msg {
                    font-size: 1rem;
                    font-weight: 400;
                    color:kt-base-color(label, 2);
                }

                .kt-login__account-link {
                    font-size: 1rem;
                    font-weight: 500;
                    color: kt-base-color(label, 3);
                    transition: color 0.3s ease;

                    &:hover {
                        color: kt-brand-color();
                        transition: color 0.3s ease;
                    }
                }
            }
        }
    }
}

// Login Modes
.kt-login.kt-login--v4 {
    &.kt-login--signin {
        .kt-login__signup {
            display: none;
        }

        .kt-login__signin {
            display: block;
        }

        .kt-login__forgot {
            display: none;
        }
    }

    &.kt-login--signup {
        .kt-login__signup {
            display: block;
        }

        .kt-login__signin {
            display: none;
        }

        .kt-login__forgot {
            display: none;
        }

        .kt-login__account {
            display: none;
        }
    }

    &.kt-login--forgot {
        .kt-login__signup {
            display: none;
        }

        .kt-login__signin {
            display: none;
        }

        .kt-login__forgot {
            display: block;
        }
    }
}

@include kt-tablet-and-mobile {
    .kt-login.kt-login--v4 {
        .kt-login__wrapper {
            padding-top: 5rem;
            width: 100%;

             .kt-login__container {
                margin: 0 auto;

                .kt-login__account {
                    margin-top: 1rem;
                }
            }
        }
    }
}

@include kt-mobile() {
    .kt-login.kt-login--v4 {
        .kt-login__wrapper {
            width: 100%;

            .kt-login__container {
                width: 100%;
                margin: 0 auto;

                .kt-form {
                    width: 100%;
                    margin: 0 auto;
                }

                .kt-login__account {
                    margin-top: 1rem;
                }
            }
        }
    }
}
