// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// @import './_cms/scss/Input.scss';
// CMS
.words-tranlation-field {
  > div {
    margin-bottom: 2rem;
  }
}

// @import "./_cms/scss/login-4.css"
// @import "/src/_cms/scss/datatables.bundle.css"
.ReactCrop {
  max-height: 600px;
  margin: 0 auto;
  width: fit-content;
  > div:first {
    max-height: inherit;
    width: fit-content;
  }
}

.ReactCrop__image {
  max-width: unset;
  max-height: 600px;
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
  background-image: url("/media/plugins/jstree/32px.png") !important;
}
.jstree-default .jstree-last {
  background-image: none !important;
}

.wrapBtn {
  background: rgb(38, 93, 212);
  height: 40px;
  width: 120px;
  position: relative;
  color: papayawhip;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  font-size: 20px;

  span {
    font-size: 13px;
    margin-left: 5px;
  }

  input[type="file"] {
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
::-webkit-scrollbar {
  width: 0;
  overflow-y: auto;
  max-height: 90%;
}
