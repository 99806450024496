.modal {
  position: relative;
  top: 0;
  z-index: 1050;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  .kt-portlet {
    .kt-portlet__head {
      padding: 0 1.25rem;
      .kt-portlet__head-label .kt-portlet__head-title {
        font-weight: 500;
        font-size: 1.3rem;
        color: #48465b;
        line-height: 1.5;
      }
    }
    .kt-portlet__body {
      padding: 1.25rem;
    }
  }
}

.modal.fade {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 576px) {
  .modal {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1024px) {
  .modal {
    max-width: 800px;
  }
}

@media (min-width: 1399px) {
  .modal {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .modal {
    max-width: 1299px;
  }
}

.modal-backdrop--over-others{
  z-index: 1050;
}